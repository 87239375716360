

import { openNotification } from "./utils";
import Web3 from 'web3';
const connectUnisatWallect = async (onSuccess, onFailure) => {
  if (typeof window.unisat !== 'undefined') {
    // console.log('UniSat Wallet is installed!');
    // console.log(window.unisat);
    try {
      let accounts = await window.unisat.requestAccounts();
      // console.log('connect success', accounts);
      openNotification('UniSat Wallet link successful ','success');
      onSuccess(accounts); // Call the onSuccess callback with the accounts
    } catch (e) {
      openNotification('connect failed','info');
      onFailure(); // Call the onFailure callback for failure
    }
  } else {
    openNotification('UniSat Wallet is not installed!','info');
    onFailure(); // Call the onFailure callback for failure
  }
};
const connectMetamaskWallet = async (onSuccess, onFailure) => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      openNotification('Metamask Wallet connected successfully','success');
      onSuccess(accounts);
    } catch (error) {
      openNotification('Failed to connect Metamask Wallet','info');
      window.open('https://metamask.io/download.html');
      onFailure();
    }
  } else {
    openNotification('Metamask Wallet is not installed','info');
    onFailure();
  }
};

const connectOKXWallet = async (onSuccess, onFailure) => {
  if (window.okxwallet) {
    try {
      await window.okxwallet.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      openNotification('OKX Wallet connected successfully','success');
      onSuccess(accounts);
    } catch (error) {
      openNotification('Failed to connect OKX Wallet','info');
      onFailure();
    }
  } else {
    openNotification('OKX Wallet is not installed','info');
    onFailure();
  }
};



export {
  connectUnisatWallect,
  connectMetamaskWallet,
  connectOKXWallet,

};