// axios 的封装
import axios from "axios";
// 获取token
import { getToken } from "./token";

// 1.根域名配置 
// 2.超时时间
// 3.拦截器 /请求 响应

const request = axios.create({
    // baseURL: 'https://api-mcc.ipolloverse.cn:18070/api/',
    timeout: 5000 * 100
})

// 添加请求拦截器

request.interceptors.request.use((config)=>{
    // 操作config  注入 header 的 token
    const token = getToken()
    if(token){
        config.headers.Authorization = `Bearer ${token}`
    }
    return config

    },(error)=>{
        return Promise.reject(error)
    }
)
// 添加响应拦截器

request.interceptors.response.use((response)=>{
    // 2xx 状态码 范围触发该函数
    return response.data
},(error)=>{
    // 超出 2xx 状态码 范围触发该函数
    // 监控 401 token 失效

    console.dir(error)
    // return error.response.data
    // if( error.response.status === 401 ){
    //     removeToken();
    //     router.navigate('/login')
    //     window.location.reload()
    // }
  
    return Promise.reject(error)
})

export { request }
