// 封装和文章相关的接口函数
import { request } from "@/utils";

const BaseURL = 'https://api-mcc.ipolloverse.cn:18070/api/'

const OB_BASE_API  = "https://obai.aimc.digital:8091/"
// https://obai.aimc.digital:8091/iPollo/brc20/checkAddress?address=bc1pxsk039kf0vu7pvlpks6j3zy7a8sgpmlsfe83djw6pqksvpfuq9nq5g82fl

export function getBrc20CheckAddressApi(params){
    return request({
        url: OB_BASE_API+'iPollo/brc20/checkAddress',
        method: 'GET',
        params
    })
}

// 1.airdrop推特任务接口 /api/v1/aivd/airdrop/twitter/task

export function postTwitterTaskAPI(data){
    return request({
        url: BaseURL+'v1/aivd/airdrop/twitter/task',
        method: 'POST',
        data
    })
}
// x授权链接获取 /api/v1/aivd/airdrop/twitter/oauth2/url

export function getTwitterOauthApi(address='bc1pqpge0y09dktsxwmeus7agmjrk2llex793k6hk22wtalgnskmqwlshuc0f2'){
    return request({
        url: BaseURL+'v1/aivd/airdrop/twitter/oauth2/url',
        method: 'GET',
        headers: { 
            'Inscription-Address':address},
    })
}

// 绑定接口 /api/v1/aivd/airdrop/twitter/bind
export function postTwitterBindAPI(data){
    return request({
        url: BaseURL+'v1/aivd/airdrop/twitter/bind',
        method: 'POST',
        data
    })
}
// bind检测 /api/v1/aivd/airdrop/twitter/bind/check

export function  getTwitterCheckApi(address){
    return request({
        url: BaseURL+'v1/aivd/airdrop/twitter/bind/check',
        method: 'get',
        headers: { 
            'Inscription-Address':address},
    })
}

//积分查询 /api/v1/aivd/airdrop/point/query 

export function getAivdPointQueryApi(address){
    return request({
        url: BaseURL+'v1/aivd/airdrop/point/query ',
        method: 'get',
        headers: { 
            'Inscription-Address':address},
    })
}
