// 绑定接口
import { useEffect } from 'react'
import { postTwitterBindAPI} from '@/apis/twitter'
import {getQueryParams, openNotification } from '@/utils'
import { useSelector } from 'react-redux'

const TwitterCallback = ()=>{

      // 获取当前的token
    const { token } = useSelector(state=>state.user)

    const postTwitterBind = async(queryParams)=>{

        try{
          let data = { address: token,...queryParams }
          // let data = { address:'bc1pqpge0y09dktsxwmeus7agmjrk2llex793k6hk22wtalgnskmqwlshuc0f2',...queryParams }
          let res = await postTwitterBindAPI(data)
          if(res.ok){
            openNotification('Successful','success')
            handleCloseWindow()
          }
          console.log('postTwitterBind',res )
        }catch(error){
          console.log( 'is why error',error.response)
          openNotification(error.response.data.description,'error')
           handleCloseWindow()
          }
      
    }
    const handleCloseWindow = () => {
        // console.log('执行一下这个状态是否关闭')
        window.close();
      };
    
    useEffect(() => {
        const url = window.location.href;
        // const url = 'http://localhost:3000/api/callback/twitter?state=2a9507d4545143af896f707e450bdbf9&code=QktWMkFUdGV0Z1Bla0ZUMFlGanNnQlUzbDhlandQZUFGM2NfaEJ2UlR0ckFKOjE3MDkxOTE5NTUxNzA6MToxOmFjOjE'
        const queryParams = getQueryParams(url);
        if(token && queryParams.state && queryParams.code){
            postTwitterBind(queryParams)
        }else{
            // handleCloseWindow()
        }
    
      }, [token]);
    return(
        <div style={{ textAlign: 'center', margin: '30px auto' }}>
            twitter alreadly used
        </div>
    )
}

export default TwitterCallback