import { Menu } from 'antd'

const MenuLayout =({currentPage,onMenuClick,menuItems,isVideoMake})=>{

    
    // console.log( '是否编辑中isVideoMake',isVideoMake)

    const selectedKey = currentPage

    // console.log( currentPage)
    return(
        <Menu
            className="header-open"
            mode="horizontal"
            theme="dark"
            defaultSelectedKeys={['/']}
            selectedKeys={selectedKey}
            // defaultOpenKeys={['/product']}
            onClick={ onMenuClick}
            items={menuItems}
            style={{ height: '100%', borderRight: 0 }}
        >
        </Menu>
    )
}

export default MenuLayout   