import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { RouterProvider } from 'react-router-dom'
import router from './router';
import { Provider } from 'react-redux'
import store from './store';
// import 'normalize.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>  严格模式 会渲染两次  {/* </React.StrictMode> */}
    <Provider store={store}>
      <RouterProvider router={router}></RouterProvider>
    </Provider>

);

