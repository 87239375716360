import { useNavigate} from 'react-router-dom'
import { Button,Dropdown,Badge } from 'antd'
import { DownOutlined,DeliveredProcedureOutlined,ClockCircleOutlined,createFromIconfontCN } from '@ant-design/icons';
import classNames from 'classnames'
import { connectUnisatWallect,formattedToken } from "@/utils"
import { startTransition,useState } from 'react';

import { Modal } from 'antd'

import { setToken,setIsWalletModalVisible} from '@/store/modules/user'
import { useDispatch,useSelector } from 'react-redux'
import './layout.scss'
import '@/assets/css/style.css'
import AnvdLogo from '@/assets/img/ANVD_black.png'
import AnvdLogo2 from '@/assets/img/ANVD_white.png'
import { Outlet,useLocation } from 'react-router-dom'
import MenuLayout from '../MenuLayout'
import WalletModal from '@/components/WalletModal';


import { setIsVideoMake,clearUserInfo} from '@/store/modules/user'


const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4426949_ks977hd76e.js', // 替换为你的 Iconfont 项目的实际 Script URL
});
const { confirm } = Modal;


function NdLayout(){
  const dispatch = useDispatch()
  const { token,isVideoMake } = useSelector(state=>state.user)

  // console.log('token',token)

  // 获取当前的路由值
  const location = useLocation()
  const currentPage = location.pathname
  // 定义是否点击 显示 h5 和 pc 的头部
  const [isH5Header, setIsH5Header] = useState(false);

const handleH5Visible = (route)=>{
  // console.log( flag)
  setIsH5Header(!isH5Header)
  if( route ){
    onMenuClick(route)
  }

}

// 获取当前的路由值
const menuItems= [
  {
     label: 'Home',
    key: '/'
  },
  {
    label: 'Product',
    key: '/product',
    children: [
      {
        label: 'Creater V1',
        key: '/product'
      },
      {
        label: 'Creater V2',
        key: 'https://ainn.video/createV2'
      },
    ]
  },
  {
    label: <Badge className="Badge-menu" count="Soon!" >Airdrop</Badge> ,//'Leaderboard',
    key: '/airdrop',
    disabled: true,
  },
  {
    label: <Badge className="Badge-menu" count="Soon!" >Leaderboard</Badge> ,//'Leaderboard',
    key: '/Leaderboard',
    disabled: true,
  }
]

  // 子组件 方法
  const navigate = useNavigate();
  const onMenuClick = (route)=>{
      const path = route.key;
      console.log( 'path',path)
      if( path.indexOf('http') !== -1){
        window.location.href = path;
        return
      }
      if( currentPage === '/product' && isVideoMake  ){
          if(path !=='/product'){
             return showConfirm(path)
          }
        
      }else{
          navigate(path)
      }
      
  }
  const showConfirm = (path,onOkCallback,title,content) => {
      confirm({
        title: title ||'Are you sure you want to cancel the creation?',
      //   icon: <ExclamationCircleFilled />,
        icon: null,
        content: content || 'You are creating a video. Leaving the current page will NOT result in the cancellation of the creation. Clicking user info at the top right corner, you can find all the videos. Leave now?',
        okText: 'OK', // 自定义确定按钮文本
        cancelText: 'Cancel', // 自定义取消按钮文本
        okButtonProps: {
          className: 'custom-cancel-button custom-ok-button',
          // 其他样式属性
        },
        cancelButtonProps: {
          className: 'custom-cancel-button',
          // 其他样式属性
        },
    
        onOk() {
          console.log('OK');
          // console.log('是否存在onOkCallback', onOkCallback)
          if( onOkCallback ){
            onOkCallback()
          }else{
            dispatch(setIsVideoMake(false))
            navigate(path)
          }

        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };

  // 子组件方法
;
  const handleLinkMenu = ()=>{
    if( currentPage === '/product' && isVideoMake  ){
      return showConfirm('/')
    
    }else{
        navigate('/')
    }
  }
  const handleConnect = (isH5Header)=>{
    // console.log('点击链接 uniswap');
    if( isH5Header ){
      setIsH5Header(!isH5Header)
    }
    dispatch(setIsWalletModalVisible(true))
   return
  // Pass two callback functions to handle success and failure
    connectUnisatWallect(
      (accounts) => {
        // console.log('连接成功后的处理', accounts);
      
        if( !token ){
          dispatch(setToken(accounts[0]))
        }
        // 传入 设置 token

        // Perform actions after successful connection
      },
      () => {
        // console.log('连接失败后的处理');
        // Perform actions after failed connection
      }
    );

    // console.log('点击链接 uniswap');
  }
  //断开链接
  const handleDisconnect = ()=>{
    // console.log('断开钱包链接');
    // 每次断开 都关闭当前模态窗
    setIsH5Header(false)

    const handleDisconnectConfirm = () => {
      dispatch(clearUserInfo());
    };
  
    if (currentPage === '/product' && isVideoMake) {
      const title = 'Are you sure you want to disconnect the wallet?';
      const content = 'You are creating a video. Disconnecting the wallet link will cause the creation to be cancelled.';
      showConfirm('/', handleDisconnectConfirm, title, content);
    } else {
      handleDisconnectConfirm();
    }
  
    if (currentPage === '/video-history') {
      navigate('/');
    }
  }
  // 跳转 目录
  const  goHistory = ()=>{
    // navigate('/video-history') 
     // 每次断开 都关闭当前模态窗
    setIsH5Header(false)
    startTransition(() => {
      navigate('/video-history') 
      // 这里放置可能会触发暂停的更新操作
      // 例如，设置某个状态或调用某个异步函数
    });
  }
  // 点击下拉的类目项
  const items = [
    { 
      key: '1',
      label: (
        <span onClick={ goHistory } className='disconnect-box'><ClockCircleOutlined /> Video History</span>
      )
    },
    { 
      key: '2',
      label: (
        <span onClick={ handleDisconnect } className='disconnect-box'><DeliveredProcedureOutlined />  Disconnect</span>
      )
    }
  ]


 
    return(
      
        // <Layout className={classNames( NdLanguage ==='zh' ? 'cn-font-family':'en-font-family','web-root' )} style={{ backgroundImage: `url(${backgroundImage})`}}>
        < >
          <div className='TopTips-tip'>
              <div className='TopTips-container'>
                <div className='TopTips-message'> 
                    NEW | Partner Recruitment - Join AINN.video Partner Program!
                </div>
              </div>
          </div>
          <div className={ classNames('header header-absoulate') } >
              <div className='container '>
                <div className='row'>
                    <img  className="logo" src={AnvdLogo} onClick={handleLinkMenu} alt=""/>
                    {/* <img className="logo" src='https://assets-global.website-files.com/63fdcbe48aa020c0a5493507/649e4c3d5f0708ca6cd80bba_logoHorizonalLight.svg' alt='' /> */}
                    <MenuLayout currentPage={currentPage} menuItems={menuItems}    onMenuClick={onMenuClick} isVideoMake={isVideoMake} />
                    {
                      token ? 
                        <Dropdown
                          className='header-open'
                          menu={{
                            items,
                          }}
                          trigger={['click']}
                        >
                          <div className='connected-user-button' onClick={(e) => e.preventDefault()}>
                            { formattedToken(token) }
                            <DownOutlined />
                            </div>
                        </Dropdown>
                      :
                      <div className='content-wallet header-open' onClick={ ()=>handleConnect()}>
                        <Button>Connect</Button>
                      </div>
                    }
                    <div className='header-menu' >
                      {
                        isH5Header ? 
                          <IconFont onClick={ ()=> handleH5Visible()} type="icon-x" />
                        :
                        <IconFont onClick={()=>  handleH5Visible()} type="icon-h5-id-friend-number" /> 
                      }
  
                     </div>
                  
                  </div>
              </div>
              {
                isH5Header &&    <div className='open-page-menu'>   
                      <div className='container'>
                              <div className='flex-wrap'>
                                <div className='menu-list'>
                                  <div className='menu-list-items'>
                                    {
                                    token ? 
                                      <Dropdown
                                        menu={{
                                          items,
                                        }}
                                        trigger={['click']}
                                      >
                                        <div className='connected-user-button' onClick={(e) => e.preventDefault()}>
                                          { formattedToken(token) }
                                          <DownOutlined />
                                          </div>
                                      </Dropdown>
                                    :
                                    <div className='content-wallet' onClick={ ()=>handleConnect('isH5Header')}>
                                      <Button>Connect</Button>
                                    </div>
                                  }
                                  </div>
                                  {
                                     menuItems.map((route,index)=>{
                                      return(
                                        <div className='menu-list-items' onClick={()=> handleH5Visible(route)} key={index}>
                                          {/* Home */}
                                          {route.label}
                                        </div>
                                      )
                                     })
                                  }
                                 
                                  {/* <div className='menu-list-items'>
                                    Home
                                  </div>
                                  <div className='menu-list-items'>
                                    Product
                                  </div> */}
                                </div>
                        
                              </div>
                          </div>
                  </div>

              }
           
            </div>
            <div className='page-wrapper'>
                {/* 子元素占位符 */}
                <Outlet />
            </div>
            <div className='Aivd-footer'>
              <div className='container '>
                <div className="GlobalFooter-logo">
                    <img  className="logo" src={AnvdLogo2} alt="" onClick={handleLinkMenu}/>
                    {/* <img className="logo" src='https://assets-global.website-files.com/63fdcbe48aa020c0a5493507/649e4c3d5f0708ca6cd80bba_logoHorizonalLight.svg' alt='' /> */}
  
                </div>
                <div className="GlobalFooter-desc">
                  Provide text-generated video to build basic models for global users, and continue to improve and optimize, and will support more languages in the future, including Spanish, Russian, Japanese, Korean,etc.., providing top-level text-generated speech, voice cloning, voice fusion with lip movements and expressions, and other cutting-edge AI technologies.
                  </div>
              </div>
              <div className="GlobalFooter-bottom">Copyright @AINN.video 2024 <span>GPU Powered by NGPU. </span></div>
            </div>
            <WalletModal/>
        </>
    )
}
export default NdLayout