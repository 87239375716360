import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { request } from "@/utils";
import request from "@/service/request";

// 异步操作：获取素材
export const getMaterial = createAsyncThunk('material/getMaterial', async () => {
  const responses = await Promise.all([
    request({
      url: 'https://haixin-fang.github.io/vue-design-editor-static/imglist.json',
      timeout: 10000,
      method: 'get',
    }),
    request({
      url: 'https://haixin-fang.github.io/icons/bootstrap-icons.json',
      timeout: 10000,
      method: 'get',
    }),
  ]);

  const [imageList, iconList] = responses;
  const icons = Object.keys(iconList).map((item) => ({
    url: `https://haixin-fang.github.io/icons/icons/${item}.svg`,
  }));
  return { imageList, icons };
});

const material = createSlice({
  name: 'material',
  initialState: {
    isClose: true,
    activeModule: null,
    imageList: [],
    loadOk: false,
    icons: [],
    selectedItem: null, // 选中的画布元素
    workspace: null, // 画布对象
  },
  reducers: {
    setClose(state, action) {
      state.isClose = action.payload;
    },
    setActiveModule(state, action) {
      state.activeModule = action.payload;
    },
    // Ok(state, action) {
    //   console.log( 'action',action.payload)
    //   state.loadOk = true;
    //   state.imageList = action.payload;
    // },
    // COMMIT_ICONS(state, action) {
    //   state.icons = action.payload;
    // },
    setActivateItem(state, action) {
      state.selectedItem = action.payload;
    },
    setWorkarea(state, action) {
      state.workspace = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMaterial.fulfilled, (state, action) => {
      state.loadOk = true;
      state.imageList = action.payload.imageList;
      state.icons = action.payload.icons;
    });
  },
});

// 导出同步 action
export const { setClose, setActiveModule, setActivateItem, setWorkarea,ok,COMMIT_ICONS } = material.actions;

const materialReducer = material.reducer

// 导出 reducer
export default materialReducer;
