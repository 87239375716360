// 路由配置
import { AuthRouter } from "@/components/authRouter";
import Layout from "@/Layout/NdLayout";
import TwitterCallback from "@/pages/callback/twitter";

// 配置路由实例
import { createBrowserRouter } from 'react-router-dom'
// 二级路由
// import Home from "@/pages/Home";
// import Article from "@/pages/Article";
// import Publish from "@/pages/Publish";

// 配置 路由懒加载

import { Suspense, lazy } from "react";

const Home = lazy(() => import("@/pages/Home"));
const Product = lazy(() => import("@/pages/Product"));
// const Airdrop = lazy(()=> import('@/pages/Airdrop'));
const VidoeHistory = lazy(() => import("@/pages/VideoHistory"));


// const Publish = lazy(() => import("@/pages/Publish"));



const router = createBrowserRouter([
    {
        path: '/',
        // element: <AuthRouter><Layout/></AuthRouter> ,
        element: <Layout/> ,
        children:[
            {
                index: true,
                element: <Suspense fallback={'loading...'}> <Home/></Suspense>
            },
            {
                path: 'product',
                element: <Suspense fallback={'loading...'}><Product/></Suspense>
            },
            // {
            //     path: 'creator',
            //     element: <Suspense fallback={'loading...'}><Creator/></Suspense>
            // },
            // {
            //     path: 'airdrop',
            //     element: <Suspense fallback={'loading...'}><Airdrop/></Suspense>
            // },
            {
                path: 'video-history',
                element: <AuthRouter><VidoeHistory/></AuthRouter>
            },
            
        ]
    },
    {
        path: 'api/callback/twitter',
        element: <TwitterCallback/>
    },

])

export default router