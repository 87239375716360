// 封装高阶组件 有token 正常跳转 没有token 跳转 登录
import { getToken } from "@/utils"
import { Navigate } from 'react-router-dom'

export function AuthRouter({children}){
    const token = getToken()
    // console.log(token)
    if(token){
        return  <>{children}</>
    }else{
        return <Navigate to={'/'} replace />
    }
    
}
