// 组合模块
import  { configureStore } from '@reduxjs/toolkit'
import userReducer from './modules/user'
import materialReducer from './modules/material'

const store = configureStore({
    reducer:{
        user: userReducer,
        material: materialReducer
    }
})

export default store