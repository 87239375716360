// 和用户相关 状态管理 
import { createSlice } from '@reduxjs/toolkit'
import { setToken as _setToken,getToken,removeToken} from '@/utils';
import { LoginAPI,getProfileAPI } from '@/apis/user';

const userStore = createSlice({
    name: 'user',
    // 数据状态
    initialState: {
        token: getToken() || '',
        isVideoMake: false,
        isMounted: false,
        isWalletModalVisible: false
    },
    //  同步方法
    reducers:{
        setToken(state,action){
            state.token = action.payload
            _setToken(action.payload)
        },
        setIsVideoMake(state,action){
            state.isVideoMake = action.payload
        },
        setIsMounted(state,action){
            state.isMounted = action.payload
        },
        setIsWalletModalVisible(state,action){
            state.isWalletModalVisible = action.payload
        },
        clearUserInfo(state){
            state.token = ''
            // state.isVideoMake = false
            removeToken()
        }
    }
})


// 解构出 actionCreater

const { setToken,setIsVideoMake,setIsMounted,setIsWalletModalVisible,clearUserInfo } = userStore.actions;


const userReducer = userStore.reducer;

// 异步方法 完成登录 获取 token

const fetchLogin = ( loginForm) =>{
    return async (dispatch)=>{
        // 发送异步请求
        // const res = await request.post('/authorizations',loginForm)
        const res = await LoginAPI(loginForm)
        console.log( res )
        // 提交 同步的action 进行 token 的存入数据
        dispatch(setToken(res.data.token))
    }
}
// 获取个人用户信息异步方法

const fetchUserInfo = () =>{
    return async (dispatch)=>{
        // 发送异步请求
        // const res = await request.get('/user/profile')
        const res = await getProfileAPI()
        console.log( res )
        // 提交 同步的action 进行 token 的存入数据
        // dispatch(setUserInfo(res.data))
    }
}

export { setToken,fetchLogin,setIsVideoMake,setIsMounted,setIsWalletModalVisible,fetchUserInfo,clearUserInfo }

export default userReducer

