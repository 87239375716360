// 封装token 的存 取 删
const TOKENKEY = 'token_address'


function setToken(token){
    localStorage.setItem(TOKENKEY,token)
}
function getToken(){
   return localStorage.getItem(TOKENKEY);
}
function removeToken(){
    return localStorage.removeItem(TOKENKEY)
}

export {
    setToken,
    getToken,
    removeToken
}