import { notification} from  'antd'
import { CheckCircleOutlined, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
const handleDownload=(videoUrl)=>{
    // let videoUrl='https://obai.aimc.digital/20240130110207671852.mp4'
    // 使用正则表达式匹配文件名
  
    console.log('使用正则表达式匹配文件名', videoUrl)
    const match = videoUrl.match(/\/([^\/]+)$/);
  
    // 获取匹配到的文件名
    const fileName = match ? match[1] : 'video.mp4';
    fetch(videoUrl)  
    .then(response => response.blob())  
    .then(blob => {  
        const url = window.URL.createObjectURL(blob);  
        const a = document.createElement('a');  
        a.style.display = 'none';  
        a.href = url;  
        a.download = fileName;  
        document.body.appendChild(a);  
        a.click();  
        window.URL.revokeObjectURL(url);  
        document.body.removeChild(a);  
    })  
    .catch(e => console.error(e));
  
  }
  const openNotification = (message, status) => {
    let icon;
  
    switch (status) {
      case 'success':
        icon = <CheckCircleOutlined style={{ color: '#52c41a' }} />;
        break;
      case 'info':
        icon = <InfoCircleOutlined style={{ color: '#1890ff' }} />;
        break;
      case 'error':
        icon = <CloseCircleOutlined style={{ color: '#f5222d' }} />;
        break;
      default:
        icon = null;
    }
  
    notification.open({
      message: message,
      description: null,
      icon: icon,
      placement: 'topRight',
    });
  };
  const formattedToken = ( token)=>{
    return   token ? `${token.slice(0, 5)}...${token.slice(-5)}` : null;
  
  }
  const  formatToLocalDateTime =(dateTimeString)=> {
    const date = new Date(dateTimeString);
    const currentDate = new Date();
  
    const isSameDay = date.getDate() === currentDate.getDate() &&
                      date.getMonth() === currentDate.getMonth() &&
                      date.getFullYear() === currentDate.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    if (isSameDay) {
      
      return `${hours}:${minutes}`;
    } else {
      // const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      // return `${year}-${month}-${day}`;
      return `${month}-${day} ${hours}:${minutes}`;
    }
  }
  // 加载视频生成图片
  const fetchThumbnail = async (videoUrl) => {
    console.log('加载视频生成图片', videoUrl);
    
    // 创建视频元素
    const video = document.createElement('video');
    video.src = videoUrl;
    video.preload = 'metadata'; // 仅加载视频元数据，不加载完整视频
    video.muted = true; // 将视频静音
    video.setAttribute('playsinline', ''); // 在 iOS 上，需要将 playsinline 设置为 true 才能在页面内播放视频
  
    // 等待视频元数据加载完成
    await video.play().catch(error => {
      // 捕获播放失败的错误，这通常是因为浏览器阻止了自动播放
      console.error('Error playing video:', error);
    });
  
    // 创建画布并绘制视频的第一帧图片
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
    // 将画布内容转换为图片 URL
    const thumbnailUrl = canvas.toDataURL('image/jpeg');
    console.log('返回生成的缩略图', thumbnailUrl);
  
    // 返回生成的缩略图 URL
    return thumbnailUrl;
  };
  const getQueryParams = (url)=> {
    const params = {};
    const queryString = url.split('?')[1];
    if (queryString) {
        const keyValuePairs = queryString.split('&');
        keyValuePairs.forEach(keyValue => {
            const [key, value] = keyValue.split('=');
            params[key] = decodeURIComponent(value);
        });
    }
    return params;
}
// 每隔三位 展示 逗号
const  addCommasToNumber=(number)=> {
  // 将数字转换为字符串
  let numStr = number.toString();
  // 将字符串拆分为整数部分和小数部分（如果有）
  let parts = numStr.split(".");
  // 整数部分添加逗号
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // 将整数部分和小数部分（如果有）重新组合
  return parts.join(".");
}
  
  export{
    handleDownload,
    openNotification,
    formattedToken,
    formatToLocalDateTime,
    fetchThumbnail,
    getQueryParams,
    addCommasToNumber
  }