// useWalletConnect.js
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setToken,setIsWalletModalVisible} from '@/store/modules/user'
import { connectUnisatWallect, connectMetamaskWallet, connectOKXWallet } from '@/utils'


const useWalletConnect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);

  const handleConnectUnisat = async (option) => {
    // console.log('点击链接 uniswap');
    console.log( 'option',option)

    try {
      connectUnisatWallect(
        (accounts) => {
          // console.log('连接成功后的处理', accounts);
        
          if( !token ){
            dispatch(setToken(accounts[0]))
            if( !option ){
                navigate('/product');
            }
          }
          if( !option ){
            navigate('/product');
          }
        },
        () => {
          console.log('连接失败后的处理');
          // Perform actions after failed connection
        }
      );
    } catch (error) {
      console.log('连接失败后的处理');
      // 可以在这里处理连接失败的逻辑
    }
  };
  const handleConnectMetamask = async (option) => {
    try {
      connectMetamaskWallet(
        (accounts) => {
          // 连接成功后的处理
          if (!token) {
            dispatch(setToken(accounts[0]));
            
            if (!option) {
              navigate('/product');
            }
          } else if (!option) {
            navigate('/product');
          }
        },
        () => {
          console.log('连接失败后的处理');
          // 在这里处理连接失败的逻辑
        }
      );
    } catch (error) {
      console.log('连接失败后的处理');
      // 在这里处理连接失败的逻辑
    }
  };
  
  const handleConnectOKX = async (option) => {
    try {
      connectOKXWallet(
        (accounts) => {
          // 连接成功后的处理
          if (!token) {
            dispatch(setToken(accounts[0]));            
            if (!option) {
              navigate('/product');
            }
          } else if (!option) {
            navigate('/product');
          }
        },
        () => {
          console.log('连接失败后的处理');
          // 在这里处理连接失败的逻辑
        }
      );
    } catch (error) {
      console.log('连接失败后的处理');
      // 在这里处理连接失败的逻辑
    }
  };
  

  const handleIsToken = () => {
    if (!token) {
      // handleConnectUnisat();
      dispatch(setIsWalletModalVisible(true))
    } else {
      navigate('/product');
    }
  };

  return {
    handleConnectUnisat,
    handleConnectMetamask,
    handleConnectOKX,
    handleIsToken,
  };
};




export default useWalletConnect;
