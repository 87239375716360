import { Modal} from 'antd'
import useWalletConnect from "@/components/useWalletConnect"
import './walletModal.scss'
import { useDispatch,useSelector } from 'react-redux'

import { setIsWalletModalVisible} from '@/store/modules/user'
import MetaMaskImg from '@/assets/img/metamask.png'
import okxImg from '@/assets/img/okx_wallet.png'
import UnisatImg  from '@/assets/img/unisat.svg'


const WalletModal = ()=>{
     // 当前弹出 推特链接的的弹出窗
//   const [isWalletModal, setIsWalletModal] = useState(true);
  const dispatch = useDispatch()

  const { isWalletModalVisible } = useSelector(state=>state.user)

  // 判断当前钱包是否链接
//   const { handleConnect } = useWalletConnect();
const {
    handleConnectUnisat,
    handleConnectMetamask,
    handleConnectOKX,
} = useWalletConnect();

//   const handleEarnClick = () => {
//         // 在此处执行传递的回调函数
//         handleConnect('create')
//         dispatch(setIsWalletModalVisible(false))
//         // setIsWalletModal(false)
//   };

const handleEarnClick = (name) => {
    // return
    switch (name) {
        case 'MetaMask':
            handleConnectMetamask('create');
            break;
        case 'OKX Wallet':
            handleConnectOKX('create');
            break;
        case 'Unisat Wallet':
            handleConnectUnisat('create');
            break;
        default:
            console.log(`No handler for ${name}`);
    }
    dispatch(setIsWalletModalVisible(false))
    // dispatch(setIsWalletModalVisible(false))
    // 在此处执行传递的回调函数
    // handleConnect('create')
    // dispatch(setIsWalletModalVisible(false))
    // setIsWalletModal(false)
};


  const WalletList =[
    {
        wallet_name: 'MetaMask',
        wallet_img: MetaMaskImg,
        handleWalletClick: () => handleEarnClick('MetaMask')
    },
    {
        wallet_name: 'OKX Wallet',
        wallet_img: okxImg,
        handleWalletClick: () => handleEarnClick('OKX Wallet')
    },
    {
        wallet_name: 'Unisat Wallet',
        wallet_img: UnisatImg,
        handleWalletClick: () => handleEarnClick('Unisat Wallet')
        // handleWalletClick: handleEarnClick
    }
  ]

  const handleCancel = () => {
    dispatch(setIsWalletModalVisible(false))
        // setIsWalletModal(false)
  };



    return(
        <Modal  title="" className='wallet-modal' 
            maskClosable={false} // 禁止点击阴影框关闭弹窗 
            style={{
                top: '40%',
            }} 
            open={isWalletModalVisible}  onCancel={()=>handleCancel()} footer={null} >
            <div className='wallet-wrap'>
                <div className="wallet-title">
                    Choose Wallet
                </div>
                <div className='wallet-btns'>
                {
                    WalletList.map((item,index)=>{
                        return(
                            <div className="wallet-btn" key={index} onClick={ item.handleWalletClick} >
                                <img src={item.wallet_img} />
                                <span>
                                    {item.wallet_name}
                                </span>
                            </div>
                        )
                    })
                }
                </div>
              
            
                </div>
        </Modal>
    )

}
export default WalletModal
